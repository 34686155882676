
.form-control.is-valid{
  border-color: #666666;
  color: #666666;
}

.form-control.is-invalid{
  color: #dc3545;
}

.form-control:hover{
  cursor: pointer;
}

.form-control:focus{
  background: none;
  border-color: #111111;
  box-shadow: none;
}

.form-control.is-valid:focus{ 
  background: none;
  border-color: #111111;
  box-shadow: none;
  color: #111111;
  cursor: pointer;
}

.form-control.is-invalid:focus{
  background: none;
  border-color: #dc3545;
  box-shadow: none;
}

.form-check-input.is-valid:checked{
  background-color: #111111;
}

textarea{
  overflow-y: hidden;
}

textarea.form-control{
  background: none;
  border-radius: 4px;
  border: 1px solid #666666;
  box-sizing: border-box;

  font-family: Ubuntu;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #666666;
}

textarea.form-control:focus::placeholder {
  color: transparent;
  cursor: pointer;
}

img:before {
  content: ' ';
  display: block;
  position: absolute;
  height: 44px;
  width: 44px;
  background:#FFFFFF;
}