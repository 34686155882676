.form-control.is-valid{
  border-color: #666666;
  color: #666666;
}

.form-control.is-invalid{
  color: #dc3545;
}

.form-control:hover{
  cursor: pointer;
}

.form-control:focus{
  background: none;
  border-color: #111111;
  box-shadow: none;
}

.form-control.is-valid:focus{ 
  background: none;
  border-color: #111111;
  box-shadow: none;
  color: #111111;
  cursor: pointer;
}

.form-control.is-invalid:focus{
  background: none;
  border-color: #dc3545;
  box-shadow: none;
}

.form-check-input.is-valid:checked{
  background-color: #111111;
}

textarea{
  overflow-y: hidden;
}

textarea.form-control{
  background: none;
  border-radius: 4px;
  border: 1px solid #666666;
  box-sizing: border-box;
  
  color: #666666;
  
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  
  margin-left: 4px;
  padding: 8px 6px 0px 16px;
  width: 450px;
}

textarea.form-control:focus::placeholder {
  color: transparent;
  cursor: pointer;
}

@media (max-width: 769px) {
  textarea.form-control{
    margin: 0;
    width: 100%;
  }
  .invalid-tooltip {
    top: 45px;
    width: 100%;
  }
}

input[type="file"] {
  display: none;
}

.btn-primary:disabled {
  background-color: #b5b5b5;
}